import React, { useState, useEffect } from 'react';
import '../App.css';
import Header_NoDesc from './Header_NoDesc';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import arrow from '../assets/icons/arrow.png';
import sfxAction from '../assets/sfx/sfx_action.mp3';
import bgBody from './../assets/pics/background-plain-pc.png';
import journal from './../assets/icons/journal.png';
import journal_hover from './../assets/icons/journal_hover.png';
import hallway from './../assets/pop_up_content/hallway.jpg';
import lakeVideo from './../assets/pop_up_content/video_lake_2weeks.mov';
import sfxJournal from './../assets/sfx/sfx_journal_reveal.mp3';
import bgHeader from './../assets/pics/background-textured-pc.png'
import dadDaughter from './../assets/pop_up_content/dad_daughter.jpg';
import sfxIncorrect from './../assets/sfx/sfx_wrong_code.mp3';
import sfxCorrect from './../assets/sfx/sfx_correct_code.mp3';
import Sound_Toggle from './Sound_Toggle';

const Story = () => {
  const [currentPart, setCurrentPart] = useState(1);
  const [storyEnding, setStoryEnding] = useState(false);
  const [soundOn, setSoundOn] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalNextPart, setModalNextPart] = useState(null);
  const [randomNumbers, setRandomNumbers] = useState([]);
  const [imagePosition, setImagePosition] = useState({ bottom: '50%', left: '50%' });
  const [imageDisplayed, setImageDisplayed] = useState(false);
  const [imagePart, setImagePart] = useState(null);
  const [journalImage, setJournalImage] = useState(journal);
  const [isPositionRandomized, setIsPositionRandomized] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [visitedParts, setVisitedParts] = useState(new Set([1]));
  const [enteredCode, setEnteredCode] = useState('');
  const [isCodeCorrect, setIsCodeCorrect] = useState(false);
  const [error, setError] = useState('');
  const [hasViewedPatients, setHasViewedPatients] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [journalClickedPart, setJournalClickedPart] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const nickname = location.state?.name || 'there';
  
  const actionSound = new Audio(sfxAction);
  const journalSound = new Audio(sfxJournal);
  const incorrectSound = new Audio(sfxIncorrect);
  const correctSound = new Audio(sfxCorrect);

  const Modal = ({ show, content, onClose }) => {
    if (!show) {
      return null;
    }
  
    return (
      <div style={modalStyles.overlay}>
        <div style={modalStyles.modalContent}>
          <div style={{ backgroundImage: `url(${bgHeader})`, padding: '0px' }}><div style={modalStyles.closeButton} onClick={onClose}>X</div></div>
          <div style={modalStyles.contentText}>
            {isLoading ? (
              <div className="spinner"></div>
            ) : (
              content
            )}
          </div>
        </div>
        {error && <p style={{ color: 'rgb(255, 224, 224)', textAlign: 'center', position: 'absolute', top: '65%', left: '50%', transform: 'translate(-50%, -50%)' }}>{error}</p>}
      </div>
    );
  };

  const playSound = () => {
    if (soundOn) {
      actionSound.currentTime = 0.18;
      actionSound.play();
    }
  };

  const playJournalSound = () => {
    if (soundOn) {
      journalSound.currentTime = 0.2;
      journalSound.play();
    }
  };

  const playIncorrectSound = () => {
    if (soundOn) {
      incorrectSound.play();
    }
  };

  const playCorrectSound = () => {
    if (soundOn) {
      correctSound.play();
    }
  };

  const generateRandomNumbers = () => {
    if (nickname && randomNumbers.length === 0) {
      const numbers = Array.from({ length: 4 }, () => Math.floor(Math.random() * 10));
      setRandomNumbers(numbers);
      setModalContent(`The numbers are: ${numbers.join('')}`);
    }
  };

  const randomizeImagePosition = () => {
    const bottom = Math.random() * 80 + 10 + '%';
    const left = Math.random() * 80 + 10 + '%';
    setImagePosition({ bottom, left });
  };

  const handleJournalClick = () => {
    setShowCode(prevState => !prevState);
    setJournalClickedPart(currentPart);
    playJournalSound();
  };

  const handleViewVideoClick = () => {
    setShowModal(true);
    setIsLoading(true); 
    setTimeout(() => {
      setModalContent(
        <div>
          <video width="100%" height="auto" controls autoPlay>
            <source src={lakeVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      );
      setIsLoading(false);
    }, 1000);
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    
    value = value.replace(/\D/g, '');

    if (value.length > 4) {
      value = value.slice(0, 4);
    }

    let underscores = '';
    const remaining = 4 - value.length;
    if (remaining > 0) {
      underscores = '_'.repeat(remaining);
    }

    setEnteredCode(value + underscores);
    setError('');
  };

  const closeModal = () => {
    setShowModal(false);
    setEnteredCode('');
    setError('');
    if (modalNextPart !== null) {
      setCurrentPart(modalNextPart);
      setModalNextPart(null);
    }
  };

  const handleViewPatientsClick = () => {
    setShowModal(true);
    setIsLoading(true);
    setTimeout(() => {
      setModalContent(getModalContent());
      setHasViewedPatients(true);
      setIsLoading(false);
    }, 1000); 
  };


  const getModalContent = () => {
    if (isCodeCorrect === true) {
      return (
        <div className="hallway-div">
          <img src={hallway} alt="Clinic Hallway" className="hallway-img"/>
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: 'justify', fontFamily: 'TT Marxiana Antiqua', fontSize: '18px' }} >
          <div style={{ marginBottom: '15px' }}><b>Hi {nickname}!</b> <i>To get the code, search for Olivia's journal in the previous three pages and click on it.</i></div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ width: '150px', margin: 'auto' }}>
              <input
                  type="text"
                  value={enteredCode}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleCodeSubmit();
                    }
                    else if (e.key === 'Backspace') {
                      e.preventDefault();
                      
                      var value = enteredCode.replace(/\D/g, '');
                      value = value.slice(0, -1);
                      
                      let underscores = '';
                      const remaining = 4 - value.length;
                      if (remaining > 0) {
                        underscores = '_'.repeat(remaining);
                      }

                      setEnteredCode(value + underscores);
                      setError('');
                    }
                  }}
                  onChange={handleInputChange}
                  placeholder="____"
                  style={{
                    border: 'none',
                    outline: 'none',
                    backgroundColor: '#f1f0ef',
                    width: '130px',
                    fontSize: '40px',
                    fontFamily: 'TT Marxiana Antiqua',
                    textAlign: 'center',
                    letterSpacing: '10px',
                    paddingLeft: '10px'
                  }}
                  maxLength={5}
                  autoFocus
              />
              <button onClick={handleCodeSubmit} style={{
                    cursor: 'pointer',
                    padding: '5px 10px',
                    backgroundImage: `url(${bgHeader})`,
                    color: 'black',
                    border: 'none',
                    fontFamily: 'TT Marxiana Grotesque',
                    fontSize: '16px',
                    width: '80px',
                    margin: '15px 0px 10px 0px'
                  }}>Submit</button>
            </div>
          </div>
        </div>
      );
    }
  };

  const handleCodeSubmit = () => {
    if (enteredCode === randomNumbers.join('')) {
      setModalContent(
        <div>
          <img src={hallway} alt="Clinic Hallway" style={{ width: '100%', height: 'auto' }} />
        </div>
      );
      setIsCodeCorrect(true);
      playCorrectSound();
      setError('');
    } else if (enteredCode === '____') {
      setError('Please enter a valid code (4 digits).');
      playIncorrectSound();
    } else {
      setError('Wrong code. Please try again.');
      playIncorrectSound();
    }
  };

  const storyParts = [
    {
      part: 1,
      content:
      <>
        <div className="header-container">
            <div className="title">2 Weeks</div>
        </div>
        
        <p style={{ fontSize: '25px', fontFamily: 'TT Marxiana Grotesque'}}>Part 1</p><br/>
        <p>“Damnit. Again?” I grumbled, dragging myself out of sleep after waking up with a start. The neighbor’s radio was blaring the same Taylor Swift song, the one that encouraged you to just shake your worries away. I fumbled with my phone, my brain still smudged with the sleepiness that makes you question whether you missed a deadline or just life itself.</p><br/>
        <p>The thing is, I don’t miss deadlines. Not me. I’ve got two senior data analyst jobs, one part-time and one full-time. At 38 years old and over a decade in the industry, they could throw all the chaos they wanted at me, but I was always on top of it. Or at least, that’s the story I told myself.</p><br/>        
        <p>“Yeah, I’m the MVP,” I muttered to nobody in particular.</p><br/>        
        <p>But here’s the kicker: it’s the fifth day in a row that I’ve woken up feeling like I’ve been hit by a truck and don’t even know what day it is. It was also the fifth day of my growing awareness that the more superbly I performed at work, the more work was piled onto me by my supervisors. It was a never-ending cycle of exhaustion.</p><br/>        
        <p>I glanced at the wall clock, which stubbornly insisted it was 8:55 AM. I dragged myself out of bed and stumbled over to my desk. At least I could work from home. I told myself I had plenty of things to be grateful for as I powered up my computer and prepared for another day of grinding away.</p><br/>        
        <p>While I waited for my computer to boot up, I noticed my smartphone lying on the desk. I picked it up, scrolling through the photos I’d taken. Photography had become a small refuge for me—a way to find fleeting moments of beauty amidst the grind. I’d started using my phone’s camera to capture everyday scenes, the simple yet satisfying act of finding beauty in the mundane. It was a brief escape, a reminder of life outside the office walls.</p><br/>        
        <p>I buried myself in work, adding tasks to my calendar like a Wendy’s salad in the nineties. My phone’s shrill ring jolted me back to reality. It was past 8 PM? How did time turn into a runaway train?</p><br/>        
        <p>“Hey, Livvy,” my friend Jane’s voice crackled through the receiver, sounding like she was trying to cut through the fog of my exhaustion. Now a bookshop owner, I met Jane in my senior year in uni when we took the same art history classes. We had been close friends since.</p><br/>        
        <p>“Haven’t heard from you in ages,” she said.</p><br/>        
        <p>“Yeah, well, working all the time,” I said.</p><br/>        
        <p>“Wanna go out for drinks? Coffee? Tea?”</p><br/>
        <p>“Not today. Too busy. But thanks.”</p><br/>
        <p>There was a pause, then she said, “Liv, you need to take a break. Ever since your dad passed away—”</p><br/>
        <p>“That was a year ago. I’m fine.”</p><br/>
        <p>“It wouldn’t hurt to talk to someone.”</p><br/>
        <p>“It wouldn’t, but I’m good.”</p><br/>
        <p>Jane let it drop, and we exchanged a few more pleasantries before hanging up.</p><br/>
        <p>I turned back to the computer screen, but suddenly the room felt like it was spinning. My eyes were so tired they felt like they’d been on a marathon. I decided to work from bed, my phone in hand, and the minutes ticked away like they had their own agenda. The dizziness didn’t ease up, and soon, exhaustion crashed over me like a tidal wave. I let it take me under and passed out in bed.</p><br/>
        <p>As I lay there, a dark thought brushed against the edges of my mind, a fleeting whisper that maybe it would be easier if I wasn’t here at all. I shook it off, too tired to grapple with it. But the thought lingered, making me shudder as I drifted into a troubled sleep.</p><br/>
        <p>The more I sank into the depths of my weariness, the more that whisper grew. It was as if the weight of my life’s demands and my father’s absence were pressing me into a corner with no escape. The idea of ending it all seemed less like a distant fantasy and more like a tangible solution, a release from the relentless cycle of pain and exhaustion. The thought was never far from my mind, a constant companion in my darkest moments.</p><br/>
        </>,
      options: [
        { text: 'Proceed to Part 2', nextPart: 2 },
      ],
    },
    {
      part: 2,
      content:
      <p>
        <p style={{ fontSize: '25px', fontFamily: 'TT Marxiana Grotesque', marginTop: '10px'}}>Part 2</p><br/>
        I dragged myself out of bed, every limb feeling raw and heavy. I figured it was high time to grab some medicine and vitamins. Maybe they'd help shake off this relentless fatigue and dizziness that had practically become my shadow.<br/><br/>
        
        Stepping outside was like walking into a spotlight; the sunlight hit me so hard I felt like I’d just stepped onto a stage. But as I lurched down the street to the newly opened Watsons right next to an old 7-11, my vision narrowed and darkened, like someone was slowly drawing the blinds. Each step felt like I was dragging weights, and before long, I was back at home, defeated and deflated.<br/><br/>
        
        Inside, I flopped onto the couch and fumbled for my phone. Another friend, Miles, had called. Miles was a graphic designer and one of my oldest friends. <br/><br/>
        
        His voice was tinged with worry as he asked if we were still on for a comic convention later. <br/><br/>
        
        “Sorry, but I can’t make it,” I said, my voice sounding hollow even to me. When he suggested I see a doctor, I cut him off with a curt “I’m fine” and hung up.<br/><br/>
        
        The next day, I managed to get myself outside again, hoping the fresh air might clear the fog in my head. As I shuffled along, an old man came into view, his posture and face eerily reminiscent of my father. My heart did a weird, painful skip, but as he got closer, I realized he was just a stranger.<br/><br/>

        The sudden, sharp reminder of my father’s absence was like a punch to the gut. He’d always been there, but never truly present—distant, especially during the rough patches when I needed him most. His absence had left a void so deep that even when he was around, it felt like I was always facing the world alone, vulnerable to everything it could throw at me. Those moments when he should have shielded me from pain, he wasn’t there, and I was left exposed, grappling with shadows I couldn’t name.<br/><br/>
        
        Now, with him gone forever, the reality of that lost protection crashed over me. I felt the grief slam into me with a force that left me breathless. I retreated back to my apartment, overwhelmed, crawling into bed as the haze closed in again. The persistent pinging and ringing of my mobile phone felt like a harsh intrusion, but I ignored it, unable to face the world beyond my darkened room.<br/><br/>
        
        In a moment of desperation, I thought again about the dark whisper from the other night. The thought of ending it all seemed almost like a release from the unending pain. I felt paralyzed, unable to act on it but unable to shake it off either. My body felt like it was frozen in place, capable of only sporadic twitches, while my mind raced with the unbearable heaviness of my emotions.<br/><br/>
        
        When I woke up the next morning, nothing had changed. My world was still wrapped in this dark, hazy blanket. Panic set in as I tried to move, only to find my body feeling like it was frozen in place, capable of only sporadic twitches. Fear gripped me as I lay there, helpless. The sluggishness seemed endless.<br/><br/>
        
        At that moment, I knew that something had to change. Life would go on for so many - my father would continue being gone, my friends would continue to be concerned but unable to help, my work would continue its punishing momentum, my supervisors would keep making demands despite my breaking point – but there would be no living for me. Not when my definition of release was an end to my hopes. <br/><br/>
        
        In a moment of desperation, I managed to book an appointment with a psychiatrist. After making the appointment, lying back on my bed, the full weight of my situation crashed down on me. The sense of helplessness was suffocating, and all I could do was wait for the day to come, trapped in a fog that felt like it would never lift.<br/><br/>
        </p>,
      options: [
        { text: 'Proceed to Part 3', nextPart: 3 },
      ],
    },
    {
      part: 3,
      content: 
      <p>
        <p style={{ fontSize: '25px', fontFamily: 'TT Marxiana Grotesque', marginTop: '10px'}}>Part 3</p><br/>
        Major depressive disorder. That’s what I have. When all was said and done, my psychiatrist’s diagnosis confirmed something I think I had always known deep down, even when I was a lot younger. <br/><br/>

        The days before my appointment had dragged on like an endless blur. When the day finally rolled around – two weeks after I had first started my downward spiral – I found myself sitting in the hallway outside the clinic along with other patients, caught between feeling trapped and numb. I’d taken this step, but facing it felt like another hurdle.<br/><br/>
        
        The hallway was well lit with plastic benches on both sides. There were many patients waiting on that Saturday, a mix of people in their 20s to 50s, some wearing brightly colored clothes and clad in all black. A group of women with brightly colored hair chatted quietly amongst themselves. A couple of people were exchanging notes about previous sessions. I sat by myself in the far end of the hallway, watching warily as a woman in her twenties with bandages on her wrists went inside the clinic. <br/><br/>
        
        The line was long. I must have waited five hours before the doctor could see me. It was late night by then. <br/><br/>
      
        My psychiatrist, Dr. H, is a tall woman in her sixties and wearing horn-rimmed glasses. She was patient and unassuming, like a calm anchor in the middle of a storm. It took me several halting sentences (and one failed attempt at a corny depression joke where Dr. H’s face remained impassive) before I could start to verbalize how I felt. <br/><br/>
        
        She listened with a sort of quiet attention that felt almost comforting amidst my chaos. I rambled on about my father, the weight of his absence, and the grief that seemed to choke me from all sides. It was a small release, painful but not quite the cathartic breakthrough I’d been hoping for.<br/><br/>
        
        We started with cognitive-behavioral therapy, which felt like an alien concept at first. I was supposed to keep a journal of my thoughts and feelings—something I’d resisted because it seemed like just another thing to add to my never-ending to-do list. But I went along with it. Tracking my thoughts and challenging my negative patterns felt like another task in the ceaseless grind of my life.<br/><br/>
        
        During one session, Dr. H asked, “How’s the journaling going? Do you find it helpful to see your thoughts on paper?”<br/><br/>
  
        I scrunched up my face. “It feels pointless. I end up writing the same stuff over and over. It doesn’t really change how I feel.”<br/><br/>
  
        She leaned in. “The repetition might be revealing patterns you weren’t aware of. Sometimes, seeing these repeated thoughts helps us understand what’s driving your emotions. It’s not about instant relief but uncovering deeper truths.”<br/><br/>
  
        I wanted to snap that deeper truths weren’t helping me pay the bills, but just nodded, feeling a bit cornered.<br/><br/>
  
        As we delved deeper, Dr. H started exploring themes from my childhood. I had always believed that you made your own destiny, that the past didn’t matter if you learned from it and moved on. It was a comforting philosophy, but it was starting to crack. Dr. H began connecting my current struggles to traumatic events from my childhood that I had buried away. The idea that unresolved trauma could shadow my adult life, even unconsciously, was a harsh reality I hadn’t fully considered.<br/><br/>
  
        In one particularly grueling session, I said, “Why do we have to dig into all this childhood stuff? Isn’t it enough that I’m dealing with the loss of my father?”<br/><br/>
  
        Dr. H looked at me steadily. “Avoidance only prolongs your suffering. The grief you’re feeling now is intertwined with other unresolved issues. Addressing these childhood traumas doesn’t make things worse; it gives you a chance to understand the full scope of your pain. It’s hard, but it’s necessary for healing, Olivia.”<br/><br/>
  
        I felt a pang of frustration. The belief that I could just forge my own path seemed like a cruel joke now. The realization that my past traumas were still influencing me was overwhelming, but Dr. H’s insistence on facing them felt like the only way forward.<br/><br/>
  
        The medication she prescribed played a role too, though it wasn’t some magic fix. It helped even out the emotional extremes, making it a bit easier to think clearly and engage in therapy. But it didn’t solve everything or make the pain vanish.<br/><br/>
  
        Each week, I trudged into her office, feeling marginally less dazed but still far from healed. I was learning to navigate the wreckage of my emotions, but it felt like wading through mud, where each step forward was met with a sluggish drag.<br/><br/>
  
        — <br/><br/>
  
        I thought all was going well, albeit slowly. However, in one particular session, something Dr. H said about my father really set me off. <br/><br/>
  
        I was sitting in the armchair across from Dr. H, feeling the weight of my frustration settle heavily on my shoulders. The air conditioner hummed softly in the background, and I could hear the rustle of her notes as she reviewed them, but it all felt distant.<br/><br/>
  
        Dr. H, her expression as impassive as ever, peered over her glasses. “Olivia, we’ve been discussing how your father’s past absences during your childhood impact you. How are you feeling about that today?”<br/><br/>
  
        I gritted my teeth. “Dr. H, I’ve been coming here for months, and every session circles back to my father’s absence. I’m starting to wonder if we’re just rehashing the same issues without making any real progress.”<br/><br/>
  
        Her gaze remained steady, almost clinical. “Addressing these issues is crucial for understanding the effects of past experiences on your current mental state. It’s a process.”<br/><br/>
  
        I could feel my frustration building. “I get that, but it feels like we’re stuck in a loop. Talking about my father’s absence doesn’t seem to be helping me move forward. It’s draining.”<br/><br/>
  
        Dr. H continued to observe me with that same unflinching look. “Revisiting these topics can be repetitive, but it’s necessary to unravel the underlying patterns. Avoiding them won’t resolve the issues.”<br/><br/>
  
        That was it. My patience snapped. “You know what? I’m tired of this. I’m tired of talking about the same old stuff and getting nowhere. It’s like you’re more interested in your theories than in actually helping me. I came here hoping for progress, not to spin my wheels!”<br/><br/>
  
        Dr. H’s expression didn’t change. “If you feel this method isn’t effective, we need to reconsider our approach.”<br/><br/>
  
        I was boiling over. “Reconsider? I’ve been waiting for that to happen, but it feels like you’re just giving me the same answers and expecting different results. I’m done with this!”<br/><br/>
  
        Without waiting for her to say another word, I pushed myself out of the chair and headed for the door. My movements were sharp and decisive, fueled by the frustration that had been building up for far too long.<br/><br/>
  
        As I walked out, the door slammed shut behind me, the sound echoing in the quiet hallway. I was the last patient for the night, and it felt like I was walking through an abandoned building as I stormed my way out of the dark hallways towards the exit.  <br/><br/><br/>


        --- <br/><br/><br/>
  
        Seething with anger, I barreled into my home and cried for what must have been hours. When I looked up, it was already almost 3AM, according to my wall clock. My mind felt as if it had been wiped clean. I felt numb. My body still felt so, so heavy, but in my mind, it felt like a haze had lifted. <br/><br/>
  
        It became so clear at that moment. There was only one way for this pain—or non-pain, or whatever this is—to end. <br/><br/>
  
        I have to end my life.<br/><br/><br/>

  
        — <br/><br/><br/>

  
        I moved with a strange, methodical calm, as if I had rehearsed this countless times in my mind. I gathered a few things from around the apartment, the items almost arranging themselves in my duffel bag as though they had always belonged there. My hands moved mechanically, picking up the necessities, putting them inside, and zipping it shut. The routine felt oddly familiar, like I had known all along this was how it would turn out.<br/><br/>

  
        I pulled out a jacket and slipped it on, the weight of the fabric feeling oddly comforting against my skin. The duffel bag rested heavy in my hand, tangible and final. I walked to the door, each step feeling strangely detached, almost like I was floating.<br/><br/>
  
        As I reached for the doorknob, a faint, persistent ringing cut through the silence. My mobile phone, which I had carelessly left on the floor earlier and forgotten, began to ring. The sound was intrusive, cutting through the stillness like a knife. I paused, my hand hovering over the doorknob.<br/><br/>
  
        For a moment, I just stood there, the phone’s ring echoing in the quiet room. My heart pounded, a mix of annoyance and something else I couldn’t quite identify—perhaps a flicker of hesitation. The call felt like a last-minute intrusion, a final reminder of everything I was about to leave behind.<br/><br/>
  
        I took a deep breath and let it out slowly, the numbness in my mind feeling like it was cracking just a little. The ringing persisted, an urgent reminder of the world I was about to step away from. I glanced back at the phone, lying on the floor in the dim light of my apartment, as if it were calling out to me to reconsider.<br/><br/><br/>

  
        ---<br/><br/>
  
        I hesitated, feeling the weight of my decision shift ever so slightly. The call continued to ring, insistent and unyielding, as I stood frozen, caught between the decision I had made and the faint, persistent sound of the ringing. <br/><br/>
  
        And then – was it my imagination? The ringing kept getting louder and louder. It wouldn't stop. <br/><br/>
  
        Finally, I crossed back to where my phone lay discarded on the floor and glanced at the screen. <br/><br/>
  
        It was Jane calling.<br/><br/>
  
        I answered, trying to steady my voice. “Hey, Jane.”<br/><br/>
  
        Her voice came through bright and cheerful. “Livvy! You won’t believe what I found. I’m in Kyoto now, and guess what I stumbled into in a hidden bookshop? A first edition print of ‘Nine Stories’! I thought of you immediately!”<br/><br/>
  
        I tried to muster a response, but the words felt stuck in my throat. Jane continued, oblivious to the turmoil on my end. “This place is amazing. I had some incredible ramen and this fantastic organic fruit flavored water that I can’t wait to share with you. We definitely need to plan a trip there together—trust me, you’d love it.”<br/><br/>
  
        I tried to end the call and wrest the phone from my ear so I could finally leave, but I couldn’t move. My friend’s chatter was a gentle reminder of a world beyond my own despair. It felt oddly comforting, yet I couldn’t shake the heaviness that was swallowing me whole. Jane’s voice faltered slightly as she sensed my silence. “Liv, are you okay? You’ve been quiet.”<br/><br/>
  
        I couldn’t hold back any longer. The tears I had tried so hard to contain began to flow. I cried, feeling the weight of my decision and the crushing loneliness that had accompanied it. Jane’s voice softened, filled with genuine concern.<br/><br/>
  
        “Hey, it’s okay. You don’t have to talk about it right now. You don’t have to tell me everything. Just remember that I’m here for you. We’ll get through this together, no matter how long it takes. And so will everyone else who cares for you.”<br/><br/>
  
        I sobbed, letting her words wash over me. The warmth of her voice was like a lifeline, pulling me back from the edge I had been teetering on. “The next day is almost here,” she continued gently. “Might as well see it. <i>Sayang naman</i>.”<br/><br/>
  
        I still didn’t say anything. <br/><br/>

        “Aren’t you just a little bit curious about what’s around the corner?” she asked. <br/><br/>

        Her words seemed to dissolve some of the heaviness I was feeling. I nodded, though she couldn’t see me, and managed to choke out, “<i>Oo nga naman. Sayang din, ‘no?</i> Okay, I guess I might as well.”<br/><br/>

        “Exactly,” Jane said softly. “We’re still here, so we might as well.”<br/><br/>

        I thanked her and ended the call, the weight in my chest feeling slightly lighter. <br/><br/>

        I wiped away my tears and took a deep breath. Tomorrow might hold something worth living for after all.<br/><br/>

        I grabbed my pen and journal and started writing well into the night. In the morning, I called Dr. H to apologize for my rude exit. I asked for another appointment. She gave me one. <br/><br/>

        And we took it from there. <br/><br/>
      </p>,
      options: [
        { text: 'View other patients in the clinic hallway?',
          isPopup: true,
          action: handleViewPatientsClick
        },
        { text: 'Proceed to Part 4', nextPart: 4 },
      ],
    },
    {
      part: 4,
      content:
      <>
      <p style={{ fontSize: '25px', fontFamily: 'TT Marxiana Grotesque', marginTop: '10px'}}>Part 4</p><br/>
        One afternoon, I met Jane at this café that smelled like coffee and old books. As I began to share what had been going on, Jane looked at me with a mix of concern and curiosity.<br/><br/>

        “So, let me get this straight,” Jane said, her lips curling up in a half-smile. “You’ve been burning the candle at both ends and ignoring the fact that you’re basically running on empty?”<br/><br/>

        I managed a weak grin. “Pretty much.”<br/><br/>

        Jane’s smile softened. “You know, it’s okay to admit that you’re struggling. Sometimes we think we have to be strong all the time, but dealing with this trauma is tough. It’s like trying to outrun your shadow.”<br/><br/>

        The phrase struck a chord. My belief that you could simply create your own destiny and move on from the past now seemed naïve. The childhood trauma I had buried deep was surfacing, forcing me to confront its lingering impact on my present life.<br/><br/>

        Jane gave a little laugh. “I’ve been there. A few years back, I hit rock bottom from a job. Felt like I was drowning in my own sweat and tears. But you know, talking about it and setting some boundaries actually helped. It’s like learning to swim instead of just flailing. And of course, I started using up my vacation days.”<br/><br/>

        “Yeah, I guess that makes sense,” I said, feeling a bit less like a complete screw-up.<br/><br/>

        Jane’s smile widened. “Exactly. Don’t be too hard on yourself. It’s okay to be a little bit of a mess sometimes. It’s just…I guess it would have been easier to know that this happens to other people too, right?”<br/><br/>

        I nodded. We raised our coffee cups in a silent, not-so-cheerful, cheer.<br/><br/><br/>


        —<br/><br/><br/>


        A few days later, I met up with Miles at the park. He was sitting on a bench, doodling in his notebook like he always did. When I told him about the therapy and all the garbage I’d been dealing with, he listened intently.<br/><br/>

        “You know,” Miles said quietly, “I used to be really into photography. It was a hobby I loved before everything got so busy.” He paused, as if weighing his words. “Sometimes, I think picking it back up helped me cope with my own stuff.”<br/><br/>

        I raised an eyebrow. “Photography?”<br/><br/>

        “Yeah,” he said, nodding. “It’s finding beauty in small moments and stepping outside of your head for a while. It helped me see things differently.” He glanced at me. “Maybe it could help you, too. A way to focus on something that’s not work or all the heavy stuff.”<br/><br/>

        The suggestion took me by surprise, but it felt oddly comforting. “You think so?”<br/><br/>

        Miles smiled. “It’s worth a shot. Sometimes, connecting with something we love can give us a different perspective. Plus, it’s a way to make time for yourself, away from all the demands.”<br/><br/>

        I nodded. The idea of delving deeper into something I already liked felt like a glimmer of hope in the midst of my exhaustion.<br/><br/>

        The following day, mobile phone fully charged, I went on vacation, the first one in a long time, and the first of many.<br/><br/><br/>


        —<br/><br/>

        I spent more time with Jane and Miles in the following weeks. Our hangouts became a welcome reprieve from the grinding routine of work and therapy. There was something therapeutic about just being with friends who didn’t expect me to be “okay” all the time.<br/><br/>

        The days went by, each one a little less draining than the last. I still had bad days, but I was slowly learning how to manage them. My relationship with Jane and Miles was a small but significant part of my recovery. It was like the missing piece I hadn’t realized I needed.<br/><br/>

        Despite these moments of connection, there were still nights when the old fears and regrets resurfaced, making sleep elusive. The belief that you could outpace the shadows of your past seemed more and more like a fantasy, especially when weighed down by the scars of my childhood. The struggle to reconcile my past with my present was a constant, painful reminder that healing wasn’t just about moving forward but also about understanding and integrating the parts of myself I had tried to ignore.<br/><br/>
        </>,
      options: [
        { text: `Would you like to view the vacation video, ${nickname}?`,
          isPopup: true,
          action: handleViewVideoClick,
          nextPart: 4
        },
        { text: 'Proceed to Part 5', nextPart: 5 },
      ],
    },
    {
      part: 5,
      content:
      <>
        <p style={{ fontSize: '25px', fontFamily: 'TT Marxiana Grotesque', marginTop: '10px'}}>Part 5</p><br/>
        A month had passed since I first started dragging myself to therapy and trying out all the mindfulness exercises and sleep hygiene that made sense. It was funny how just a little bit of time could make things seem less like the end of the world.<br/><br/>
        
        Outside the cafe where I would meet my friends, the light breeze shifted, rustling some leaves by a nearby park. I looked up. The sun was on a roll, hot and steady. On any other day, I would have cursed under my breath and ran for the nearest cover. Today, I let the warm rays touch my scalp, the back of my neck, my arms. I knelt down on one knee and ran my fingers through lively, green grass. I took a deep breath.<br/><br/>
        
        Around me, families, friends, colleagues strolled by. I let the sound of hope and living wash over me as my life unpaused.<br/><br/>
        
        I walked into the café, the sunlight pouring in like it was trying to wash away all the gloom that had been sticking to me. Jane and Miles were already there, waiting at a table by the window. In all the rush and chaos of the past month and helping me out, they had become friends, too.<br/><br/>
        
        As I sat down with them and started giving an update, I felt a strange mixture of relief and apprehension. I spoke about how the therapy sessions and the mindfulness exercises were starting to stick, though it was clear that I had a long road ahead.<br/><br/>
        
        “It’s not like I woke up one day and everything was perfect,” I said. “But I’m learning to understand and manage my past traumas, not just shove them aside. It’s like learning to walk again. I’m still tripping, but I’m getting a bit steadier.”<br/><br/>
        
        Their nods were more reassuring than any pep talk. The future didn’t seem like this enormous, terrifying question mark anymore. We made plans to meet up regularly, set aside time for ourselves, and just keep each other grounded. It was comforting, in a way, to have these small commitments, like little anchors in a sea of uncertainty.<br/><br/>
        
        As I looked at Jane and Miles, I realized that maybe one crucial point of this endless grind wasn’t just to survive but to find moments of real connection, even in the midst of the mess.<br/><br/>
        
        That didn’t seem like such a bad deal.<br/><br/>
        
        With each session, I found myself slowly coming to terms with my father’s death and the childhood trauma that had affected my life in ways I hadn’t fully acknowledged. The realization that my belief in creating one’s own destiny was flawed was a hard pill to swallow. It wasn’t about erasing the past but learning to live with it.<br/><br/>
        
        In doing so, I started to see that the road to healing was about embracing both the light and the darkness of my experiences, and understanding that my past, while painful, was a part of me that I needed to confront and integrate.<br/><br/>
        
        I used to view those last two weeks before therapy as days of absolute chaos - but is it all it really was?<br/><br/>
        
        Not at all.<br/><br/>
        
        They were, indeed, the last two weeks of an old life. However, they were also the first two of a new one.<br/><br/>
        
        The day I saw a random daughter walking with her father, holding his hand, I smiled – a real one not tinged with any bitterness. I took a photo of them and sauntered along.<br/><br/>
        
        The wind was gentle and my father was in my heart.<br/><br/>
        
        I was neither okay nor not okay. What is this place called?<br/><br/>
        
        I don’t know.<br/><br/>
        
        I don’t need to know everything yet.<br/><br/>
        
        I don’t need to be okay until I am.<br/><br/>
        
        I just need to be, for now.<br/><br/>
        </>,
      options: [
        {
          text: 'View the father-daughter photo?',
          isPopup: true,
          action: () => {
            setShowModal(true);
            setIsLoading(true);
            setTimeout(() => {
              setModalContent(
                <div>
                  <img src={dadDaughter} alt="Father and Daughter" style={{ width: '100%', height: 'auto' }} />
                </div>
              );
              setIsLoading(false);
            }, 1000);
          },
          nextPart: 5
        },
        {
          text: 'Read the corny joke?',
          isPopup: true,
          action: () => {
            setShowModal(true);
            setIsLoading(true);
            setTimeout(() => {
              setModalContent(
                <div style={{ textAlign: 'justify', fontFamily: 'TT Marxiana Antiqua', fontSize: '18px' }}>
                  <div><b>Question:</b> <i>Why did the depressed woman add-to-cart a ladder in the last 8.8 Sale?</i><br /><br /><b>Answer:</b> <i>Because self-help articles on the internet kept telling her to rise above her problems.</i></div>
                </div>
              );
              setIsLoading(false);
            }, 1000);
          },
          nextPart: 5
        },
        { text: 'Exit Story', nextPart: null },
      ],
    }
  ];

  useEffect(() => {

    if (nickname && !isPositionRandomized){
    randomizeImagePosition();
    setIsPositionRandomized(true);
    }
    generateRandomNumbers();

    if (imagePart === null) {
      const randomValue = Math.random();
      if (randomValue < 0.33) {
        setImagePart(1);
      } else if (randomValue < 0.66) {
        setImagePart(2);
      } else {
        setImagePart(3);
      }
    }

    if (currentPart !== journalClickedPart) {
    setShowCode(false);
    }

    setVisitedParts(prevVisited => new Set(prevVisited.add(currentPart)));
    setModalContent(getModalContent());
  }, [currentPart, nickname, isPositionRandomized, imagePart, enteredCode, journalClickedPart]);

  const handleOptionClick = (nextPart, isPopup = false, content = '', action=null) => {
    if (action) {
      playSound();
      action();
    } else if (isPopup) {
      playSound();
      setModalContent(content);
      setModalNextPart(nextPart);
      setShowModal(true);
    } else if (nextPart !== null) {
      playSound();
      setCurrentPart(nextPart);
      var scrollableContainer = document.getElementsByClassName("story-container")[0];
      scrollableContainer.scrollTop = 0;
    } else {
      setStoryEnding(true);
      playSound();
      var scrollableContainer = document.getElementsByClassName("story-container")[0];
      scrollableContainer.scrollTop = 0;
    }
  };

  const determineNextPart = (direction) => {
    const partIndex = storyParts.findIndex(part => part.part === currentPart);
    if (direction === 'prev' && partIndex > 0) {
      return storyParts[partIndex - 1].part;
    } else if (direction === 'next' && partIndex < storyParts.length - 1) {
      return storyParts[partIndex + 1].part;
    }
    return null;
  };

  const handlePrevClick = () => {
    const prevPart = determineNextPart('prev');
    if (prevPart !== null) {
      setCurrentPart(prevPart);
      var scrollableContainer = document.getElementsByClassName("story-container")[0];
      scrollableContainer.scrollTop = 0;
    }
  };

  const handleNextClick = () => {
    const nextPart = determineNextPart('next');
    if (nextPart !== null && visitedParts.has(nextPart)) { 
      setCurrentPart(nextPart);
      var scrollableContainer = document.getElementsByClassName("story-container")[0];
      scrollableContainer.scrollTop = 0;
    }
  };

  const handleSoundToggle = () => {
    setSoundOn(prevSoundOn => !prevSoundOn);
  };

  const currentStoryPart = storyParts.find((part) => part.part === currentPart);
  const canGoNext = determineNextPart('next') !== null && visitedParts.has(determineNextPart('next'));
  const canGoPrev = determineNextPart('prev') !== null;

  return (
    <div style={{ 
      backgroundColor: '#f1f0ef', 
      backgroundSize: 'cover', 
      backgroundPosition: 'center', 
      backgroundRepeat: 'repeat', 
      height: '100vh', 
      margin: 0, 
      padding: 0,
      cursor: 'default',
      userSelect: 'none', }}

      onContextMenu={(e) => e.preventDefault()}>
      <div className="story-page">
        <Header_NoDesc/>
        <div className="story-container">
          <div className="story-item" style={responsiveStyles.storyItem}>
          {storyEnding ? (
              storyParts.map((part) => (
                <div key={part.part}>
                  <p>{part.content}</p>
                </div>
              ))
            ) : (
              <p>{currentStoryPart?.content}</p>
            )}

            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
              {!storyEnding && currentStoryPart?.options.map((option, index) => (
                <button
                  style={{
                    padding: '5px 10px',
                    backgroundImage: `url(${bgHeader})`,
                    color: 'black',
                    border: 'none',
                    cursor: 'pointer',
                    margin: 'auto 10px',
                    fontFamily: 'TT Marxiana Grotesque',
                    fontSize: '20px',
                  }}
                  key={index}
                  onClick={() => handleOptionClick(option.nextPart, option.isPopup, option.popupContent, option.action)}>
                  {option.text}
                </button>
              ))}
            </div>
          </div>
          {hasViewedPatients && (currentPart === 1 || currentPart === 2 || currentPart === 3) && imagePart === currentPart && !imageDisplayed && (
          <div className="text-wrapper" style={{ width: '80%', margin: '0'}}>
          <img 
            src={journalImage}
            alt="Journal"
            style={{
              position: 'absolute',
              float: 'right',
              marginRight: '20px',
              marginTop: '20px',
              top: imagePosition.bottom,
              left: imagePosition.left,
              width: '50px',
              height: '43.2px',
              zIndex: 1,
              cursor: 'pointer',
            }}
            onMouseEnter={() => setJournalImage(journal_hover)}
            onMouseLeave={() => setJournalImage(journal)}
            onClick={() => {
              handleJournalClick();
            }
          }
          />
          </div>
        )}
        {showCode && journalClickedPart === currentPart && (
            <div style={{
              border: '2px solid gray',
              backgroundColor: 'white',
              width: '130px', 
              textAlign: 'center',
              position: 'absolute',
              margin: '75px auto 0px auto',
              top: imagePosition.bottom, 
              left: imagePosition.left, 
              transform: 'translate(-32%, 0%)',
            }}>
              <div style={{
                backgroundImage: `url(${bgHeader})`,
                padding: '0px 10px'
              }}>
                <div style={{ textAlign: 'center', margin: '0', fontFamily: 'TT Marxiana Grotesque', fontSize: '20px' }}>Key code</div>
              </div>
              <div style={{ color: 'black', fontSize: '20px', padding: '5px 0px', fontFamily: 'TT Marxiana Antiqua' }}>
                {randomNumbers.join('')}
              </div>
            </div>
          )}
        </div>
        <div className="footer-buttons" style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px', marginTop: '20px' }}>
          {storyEnding ? (
            <Link to="/goodbye" state={{ nickname }}>
              <button style={{ 
                cursor: 'pointer',
                flex: 1,
                padding: '5px 10px',
                backgroundImage: `url(${bgHeader})`,
                color: 'black',
                border: 'none',
                margin: '0px 20px 20px 20px',
                fontFamily: 'TT Marxiana Antiqua Italic',
                fontSize: '40px',
              }}>
                The End
              </button>
            </Link>
          ) : (
            <>
              <div onClick={canGoPrev ? handlePrevClick : null} style={{ cursor: canGoPrev ? 'pointer' : 'not-allowed' }} className="prev">
                <img 
                  src={arrow} 
                  alt="Prev" 
                  style={{ transform: 'rotate(180deg)', cursor: canGoPrev ? 'pointer' : 'not-allowed', opacity: canGoPrev ? 1 : 0.5 }}
                  onClick={canGoPrev ? playSound : null}
                  className="prevButton"
                />
              </div>
              <div onClick={canGoNext ? handleNextClick : null} style={{ cursor: canGoNext ? 'pointer' : 'not-allowed' }}  className="next">
                <img
                  src={arrow}
                  alt="Next"
                  style={{ cursor: canGoNext ? 'pointer' : 'not-allowed', opacity: canGoNext ? 1 : 0.5 }}
                  onClick={canGoNext ? playSound : null}
                  className="nextButton"
                />
              </div>
            </>
          )}
        </div>

        <Modal show={showModal} content={modalContent} onClose={closeModal} />
        <Sound_Toggle soundOn={soundOn} handleSoundToggle={handleSoundToggle} />
      </div>
    </div>
  );
};


const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundImage: `url(${bgBody})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: '2px solid gray',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    textAlign: 'right',
    margin: 0, 
    padding: 0, 
    fontFamily: 'Alegreya Regular',
    width: '700px'
  },
  closeButton: {
    backgroundImage: `url(${bgHeader})`,
    backgroundSize: 'cover',
    padding: '2px 10px',
    fontWeight: 'bold',
    fontSize: '20px',
    cursor: 'pointer',
  },
  contentText: {
    fontFamily: 'Alegreya Regular',
    fontSize: '16px',
    color: '#000',
    padding: '15px',
    alignItems: 'center',
    justifyContent: 'center', 
  },
};

const responsiveStyles = {

  storyItem: {
    marginBottom: '20px',
    padding: '0 0px',
  },
  footerButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '20px',
  },
};

export default Story;