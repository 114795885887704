import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header_NoDesc from './Header_NoDesc';
import bgBody from './../assets/pics/background-plain-pc.png'
import bgHeader from './../assets/pics/background-textured-pc.png'

function Goodbye() {
  const location = useLocation();
  const nickname = location.state?.nickname || 'there';

  return (
    <div style={{ backgroundImage: `url(${bgBody})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh', margin: 0, padding: 0 }}>
      <Header_NoDesc />
      <div style={{ 
        padding: '10px 20px', 
        fontSize: '30px', 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        fontFamily: 'TT Marxiana Antiqua Italic'
      }}>
        <div style={{ marginBottom: '20px' }}>Bye, {nickname}!<br/></div>
        <div>Thanks for reading!<br/></div>
        <div style={{ marginTop: '30px' }}>
          <Link to="/">
            <button style={{ 
              padding: '5px 10px',
              backgroundImage: `url(${bgHeader})`,
              color: 'black',
              border: 'none',
              cursor: 'pointer',
              margin: '15px 20px',
              fontFamily: 'TT Marxiana Grotesque',
              fontSize: '20px', 
              cursor: 'pointer', 
              width: '100px',
            }}>
              Exit
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Goodbye;