import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header_NoDesc from './Header_NoDesc';
import bgHeader from './../assets/pics/background-textured-pc.png'
import bgBody from './../assets/pics/background-plain-pc.png'
import styled from 'styled-components';

const NameGeneratorContainer = styled.div`
  backgroundImage: url(${bgBody});
  border: 2px solid gray;
  margin: 0 auto;
  width: 19%;
  boxShadow: 0 0 10px rgba(0, 0, 0, 0.1);
  fontSize: 14px;
  fontFamily: Alegreya Regular;

  @media (max-width: 1650px) {
    width: 22%; // Adjust width for medium screens
  }

  @media (max-width: 1350px) {
    width: 27%; // Adjust width for medium screens
  }

  @media (max-width: 1100px) {
    width: 35%; // Adjust width for smaller screens
  }

  @media (max-width: 900px) {
    width: 40%; // Adjust width for mobile screens
  }

  @media (max-width: 700px) {
    width: 55%; // Adjust width for mobile screens
  }

  @media (max-width: 500px) {
    width: 70%; // Adjust width for mobile screens
  }

  @media (max-width: 400px) {
    width: 80%; // Adjust width for mobile screens
  }
`;

function NameGenerator() {
  const [nickname, setNickname] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleGenerate = () => {
    const names = ["Berryfizz", "Crumblin", "Fizzlie", "Popple", "Sconey", "Twinkletart", "Maplebop", "Waffletide", "Jellyzest", "Peppycake", "Skittloo", "Frostiq", "Truffler", "ApplePuff", "Taffylo", "Marshmelo", "Sprinkeh", "Crinkletop", "Cinnabop", "Whipple"];
    let generatedName;
    
    do {
      generatedName = names[Math.floor(Math.random() * names.length)];
    } while (generatedName === nickname);

    setNickname(generatedName);
    setError('');
  };

  const handleInputChange = (e) => {
    const input = e.target.value.trim();
    const capitalizedInput = input.charAt(0).toUpperCase() + input.slice(1);

    if (/[^a-zA-Z]/.test(capitalizedInput)) {
      setError("Nickname must contain only alphabetical characters and no spaces.");
    } else {
      setError('');
    }

    setNickname(capitalizedInput);
  };

  const handleSubmit = () => {
    const trimmedNickname = nickname.trim();
    if (/^[A-Z][a-zA-Z]*$/.test(trimmedNickname)) {
      navigate('/greeting', { state: { nickname: trimmedNickname } });
    } else {
      setError("Please enter a valid nickname (One alphabetical word only, capitalized, no spaces).");
    }
  };

  return (
    <div style={{ backgroundImage: `url(${bgBody})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh', margin: 0, padding: 0, fontFamily: 'Alegreya Regular' }}>
      <Header_NoDesc/>
      <div className="nameGen">
        <NameGeneratorContainer>
          <div style={{ 
            backgroundImage: `url(${bgHeader})`,
            padding: '10px'
          }}>
            <div style={{ textAlign: 'center', margin: '0', fontFamily: 'TT Marxiana Grotesque', fontSize: '24px' }}>Name</div>
          </div>
          <div style={{
            backgroundImage: `url(${bgBody})`, 
            backgroundSize: 'cover', 
            backgroundPosition: 'center',  
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
          }}>
            <input
              type="text"
              value={nickname}
              onChange={handleInputChange}
              placeholder='Enter name here'
              style={{ 
                backgroundImage: `url(${bgBody})`, 
                backgroundSize: 'cover', 
                backgroundPosition: 'center',  
                fontSize: '20px', 
                fontFamily: 'TT Marxiana Antiqua Italic',
                textAlign: 'center', 
                padding: '5px 10px', 
                marginTop: '20px',
                width: '250px',
                boxSizing: 'border-box'
              }}
            />
            <div style={{display: 'flex', justifyContent: 'space-between', width: '250px'}}>
              <button 
                onClick={handleGenerate} 
                style={{ 
                  cursor: 'pointer',
                  padding: '5px 22px',
                  width: '150px',
                  backgroundImage: `url(${bgHeader})`,
                  color: 'black',
                  border: 'none',
                  fontFamily: 'TT Marxiana Grotesque',
                  fontSize: '16px',
                  margin: '15px 15px 20px 0',
                }}
              >
                Choose for me
              </button>
              <button 
                onClick={handleSubmit} 
                style={{ 
                  cursor: 'pointer',
                  width: '100px',
                  padding: '5px 5px',
                  backgroundImage: `url(${bgHeader})`,
                  color: 'black',
                  border: 'none',
                  fontFamily: 'TT Marxiana Grotesque',
                  margin: '15px 0px 20px 0px',
                  fontSize: '16px',
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </NameGeneratorContainer>
        {error && <p className="error-msg">{error}</p>}
      </div>
    </div>
  );
}

export default NameGenerator;