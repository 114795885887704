import React from 'react';
import { Link } from 'react-router-dom';
import Header_NoDesc from './Header_NoDesc';
import Header_Logo from './Header_Logo';
import twoWeeksLogo from './../assets/pics/2weeks-logo.png';
import bgBody from './../assets/pics/background-plain-pc.png'

function Home() {
  return (
    <div style={{ backgroundImage: `url(${bgBody})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh', margin: 0, padding: 0 }}>
      <Header_Logo />
        <div style={{ 
          fontSize: '18px', 
          fontFamily: 'TT Marxiana Antiqua',
          position: 'absolute', 
          top: '50%', 
          left: '50%',  
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}>
          <Link to="/disclaimer">
            <img 
              src={twoWeeksLogo} 
              alt="2 Weeks" 
              style={{ 
                width: '250px',
                cursor: 'pointer',
              }} 
            />
          </Link>
          <p className="hidden">A Short Story by<br />CBPalileo</p>
        </div>
      </div>
  );
}

export default Home;