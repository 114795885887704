import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Disclaimer from './components/Disclaimer';
import NameGenerator from './components/NameGenerator';
import Greeting from './components/Greeting';
import Story from './components/Story';
import Goodbye from './components/Goodbye';

function App() {
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  const handleAcceptDisclaimer = () => {
    setDisclaimerAccepted(true);
  };

  const ProtectedRoute = ({ element, ...rest }) => {
    return disclaimerAccepted ? element : <Navigate to="/disclaimer" />;
  };

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/disclaimer"
            element={<Disclaimer onAccept={handleAcceptDisclaimer} />}
          />
          <Route
            path="/name-generator"
            element={<ProtectedRoute element={<NameGenerator />} />}
          />
          <Route
            path="/greeting"
            element={<ProtectedRoute element={<Greeting />} />}
          />
          <Route
            path="/story"
            element={<ProtectedRoute element={<Story />} />}
          />
          <Route
            path="/goodbye"
            element={<ProtectedRoute element={<Goodbye />} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
