import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header_NoDesc from './Header_NoDesc';
import bgBody from './../assets/pics/background-plain-pc.png'
import bgHeader from './../assets/pics/background-textured-pc.png'

function Greeting() {
  const location = useLocation();
  const name = location.state?.nickname || 'there';

  return (
    <div style={{ backgroundColor: '#f1f0ef', backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh', margin: 0, padding: 0 }}>
      <Header_NoDesc />
      <div style={{ 
        padding: '10px 20px', 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        fontFamily: 'TT Marxiana Antiqua Italic'
      }} className="greetings">
        <div style={{ marginBottom: '20px' }}>Hello, {name}.<br/></div>
        <div className="greeting">Welcome to 2 Weeks, a short story about life, sadness, and hope written by CBPalileo.</div>
        <div style={{ marginTop: '30px' }}>
          <Link to="/story" state={{ name }}>
            <button style={{ 
              padding: '5px 10px',
              backgroundImage: `url(${bgHeader})`,
              color: 'black',
              border: 'none',
              cursor: 'pointer',
              margin: '15px 20px',
              fontFamily: 'TT Marxiana Grotesque',
              fontSize: '20px', 
              width: '100px',
            }}>
              Enter
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Greeting;