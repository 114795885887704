import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header_NoDesc from './Header_NoDesc';
import bgHeader from './../assets/pics/background-textured-pc.png'
import bgBody from './../assets/pics/background-plain-pc.png'
import twoWeeksLogo from './../assets/pics/2weeks-logo.png';
import styled from 'styled-components';

const DisclaimerContainer = styled.div`
  backgroundImage: url(${bgBody});
  border: 2px solid gray;
  margin: 0 auto; 
  boxShadow: 0 0 10px rgba(0, 0, 0, 0.1);
  fontSize: 14px;
  fontFamily: Alegreya Regular;

  @media (max-width: 1650px) {
    width: 25%; // Adjust width for medium screens
  }

  @media (max-width: 1350px) {
    width: 30%; // Adjust width for medium screens
  }

  @media (max-width: 1100px) {
    width: 37%; // Adjust width for smaller screens
  }

  @media (max-width: 900px) {
    width: 40%; // Adjust width for mobile screens
  }

  @media (max-width: 700px) {
    width: 55%; // Adjust width for mobile screens
  }

  @media (max-width: 500px) {
    width: 70%; // Adjust width for mobile screens
  }

  @media (max-width: 400px) {
    width: 80%; // Adjust width for mobile screens
  }
`;


function Disclaimer({onAccept}) {
  const [agreed, setAgreed] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [error, setError] = useState('');
  const scrollContainerRef = useRef(null);
  const navigate = useNavigate();

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const isAtBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 1;
      if (isAtBottom) {
        setScrollToBottom(true);
      }
    }
    setError('');
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container && container.scrollHeight <= container.clientHeight) {
      setScrollToBottom(true);
    }
  }, []);

  const handleCheckboxChange = (e) => {
    if(scrollToBottom){
      setError('');
      setAgreed(e.target.checked);
    }
  };

  const handleClick = () => {
    setError("Please read the disclaimer and trigger warning first.");
  };

  const handleProceedClick = () => {
    if (agreed) {
      onAccept(); 
      navigate('/name-generator');
    } else {
      setError("Please acknowledge the disclaimer and trigger warning first.");
    }
  };

  return (
    <div style={{ backgroundImage: `url(${bgBody})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh', margin: 0, padding: 0 }}>
      <Header_NoDesc/>
      <div className="disclaimer">
        <DisclaimerContainer>
          <div style={{ 
            backgroundImage: `url(${bgHeader})`,
            padding: '5px',
          }}>
            <div style={{ textAlign: 'center', margin: '0', fontFamily: 'TT Marxiana Grotesque', fontSize: '24px' }}>Disclaimer</div>
          </div>

          <div 
            ref={scrollContainerRef}
            onScroll={handleScroll} 
            style={{ 
              overflowY: 'scroll', 
              maxHeight: '150px', 
              textAlign: 'justify', 
              padding: '0px 20px', 
              borderRadius: '0 0 10px 10px', 
              margin: '15px 10px', 
              scrollbarColor: 'gray #f1f0ef' 
            }}
          >
            <div><strong>Disclaimer: </strong>
              Your privacy is important. No data will be collected, nothing will be recorded, and nothing will be shared with anyone during your reading of this short story.</div>

            <p><strong>Trigger Warning: </strong>
              The following short story contains themes related to mental health issues, including suicidal ideation. Reader discretion is advised. If you are sensitive to or triggered by content related to mental health struggles or self-harm, please consider whether or not you wish to proceed. Your well-being is important.</p>

            <div><i>Please read through all the information carefully before proceeding with the game.</i></div>
          </div>

          <div style={{ textAlign: 'justify', marginLeft: '25px', marginRight: '25px'}}>
            <input 
              type="checkbox" 
              onChange={handleCheckboxChange} 
              onClick={handleClick}
              checked={agreed}
            />
            <label style={{ marginLeft: '10px', fontFamily: 'Alegreya Italic', fontSize: '16px'}}>
              I have read and acknowledged the statements above
            </label>
          </div>

          <div style={{ textAlign: 'right' }}>
            <button 
              onClick={handleProceedClick} 
              style={{
                padding: '5px 10px',
                backgroundImage: `url(${bgHeader})`,
                color: 'black',
                border: 'none',
                cursor: 'pointer',
                margin: '15px 20px',
                fontFamily: 'TT Marxiana Grotesque',
                fontSize: '16px'
              }}
            >
              Proceed
            </button>
          </div>
        </DisclaimerContainer>
        {error && <p className="error-msg">{error}</p>}
      </div>
    </div>
  );
}

export default Disclaimer;