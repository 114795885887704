import React from 'react';
import bgHeader from './../assets/pics/background-textured-pc.png'

function Header_NoDesc() {
    const appStyles = {
        backgroundImage: `url(${bgHeader})`,
        backgroundSize: 'cover',
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        padding: '10px 30px',
        position: 'fixed', 
        top: '0px', 
        left: '0px', 
        width: '100%', 
    };

    return (
        <div className="header-nodesc">
            <footer style={appStyles}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginRight: '5px'}}>
                    <div style={{ marginRight: '15px' }}>
                        <span style={{ fontFamily: 'TT Marxiana Grotesque' }} className="two-header">2</span>
                        <span style={{ fontFamily: 'TT Marxiana Antiqua' }} className="weeks-header">weeks</span>
                    </div>
                    <div style={{fontFamily: 'TT Marxiana Antiqua' }} className="short-header">
                        A Short Story by CBPalileo
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Header_NoDesc;