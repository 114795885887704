// import React, { useState } from 'react';
import sfxAction from '../assets/sfx/sfx_action.mp3';
// import bgHeader from './../assets/pics/background-textured-pc.png'
// import Story from './Story';
import soundOnIcon from './../assets/icons/sound-on.png';
import soundOffIcon from './../assets/icons/sound-off.png';

function Sound_Toggle({ soundOn, handleSoundToggle }) {
    // const [soundOn, setSoundOn] = useState(true);
    const actionSound = new Audio(sfxAction);

    // const playSound = () => {
    //     if (soundOn) {
    //     actionSound.currentTime = 0.18;
    //     actionSound.play();
    //     }
    // };

    // const handleSoundToggle = () => {
    //   setSoundOn(prevSoundOn => !prevSoundOn);
    // };

    const playSound = () => {
        handleSoundToggle();
        if (soundOn) {
        actionSound.currentTime = 0.18;
        actionSound.play();
        }
    }

    return (
        <div>
            <img
            src={soundOn ? soundOnIcon : soundOffIcon}
            onClick={playSound}
            alt="Sound Toggle"
            className="sound-toggle"
            />
        </div>
    );
}

export default Sound_Toggle;