import React from 'react';
import bgHeader from './../assets/pics/background-textured-pc.png'

function Header_Logo() {
    const appStyles = {
        backgroundImage: `url(${bgHeader})`,
        backgroundSize: 'cover',
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        padding: '10px 40px',
        position: 'fixed', 
        top: '0px', 
        left: '0px', 
        width: '100%'
    };

    return (
        <div style={appStyles} className="header-nodesc">
        </div>
    );
}

export default Header_Logo;